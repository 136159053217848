<template>
    <el-dialog v-dialog-drag :title="title" :width="width" :visible="visible" :close-on-click-modal="false"
        @close="handleClose">
        <el-form ref="editForm" :model="data" label-width="100px">
            <el-form-item label="采购商：" prop="Name">
                <el-input v-model.trim="data.Name" :disabled="!isNew" placeholder="请输入采购商名称" />
            </el-form-item>
            <el-form-item label="昵称：" prop="Nick">
                <el-input v-model.trim="data.Nick" placeholder="请输入采购商昵称" />
            </el-form-item>
            <el-form-item label="QQ：" prop="QQ">
                <el-input v-model="data.QQ" placeholder="请输入QQ" />
            </el-form-item>
            <el-form-item label="发货仓库：" v-if="$store.state.routes.systemSetting.MultiWarehouse" prop="WID">
                <warehouse-select :value.sync="data.WID"></warehouse-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">关闭</el-button>
            <el-button type="primary" size="small" :loading="loading" @click="handleSave">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from '@/api/base';
import { baseURL, successCode } from '@/config';
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
export default {
    name: 'CustomerEdit',
    components: {
        WarehouseSelect
    },
    data() {
        return {
            loading: false,
            visible: false,
            width: '400px',
            title: '新增',
            isNew: true,
            data: {
                Name: '',
                QQ: '',
                Nick: '',
                WID: undefined,
            },
        }
    },
    methods: {
        async init(title, row) {
            const _this = this;
            _this.visible = true;
            _this.title = title;

            if (!row) {
                _this.isNew = true;
                _this.handleClear();
            } else {
                _this.isNew = false;
                _this.data = { ...row };
            }
        },
        async handleSave() {
            const _this = this;
            if (_this.data.Name == '') {
                _this.$message({ type: 'error', message: '采购商名称不能为空' });
                return false;
            }
            const param = {
                ChangeType: 'info',
                Purchaser: JSON.parse(JSON.stringify(_this.data))
            };
            let result;
            _this.loading = true;
            if (_this.isNew) {
                param.Purchaser.PurchaserName = param.Purchaser.Name;
                param.Purchaser.PurchaseQQ = param.Purchaser.QQ;
                delete param.Purchaser.Name;
                delete param.Purchaser.QQ;
                result = await submit('/api/stall/AddPurchaser', param.Purchaser).finally(() => {
                    _this.loading = false;
                });
            } else {
                result = await submit('/api/stall/savePurchaser', param).finally(() => {
                    _this.loading = false;
                });
            }
            _this.handleClose();
            _this.$message({ type: 'success', message: '保存成功' });
            _this.$emit('handleQuery');
        },
        handleClear() {
            this.data = {
                Name: '',
                QQ: '',
                WID: undefined,
            };
        },
        handleClose() {
            this.visible = false;
            this.handleClear();
        },
    }
}
</script>